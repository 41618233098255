// Copyright 2024 - Hewlett Packard Enterprise Company

// iLO settings configuration and step keys
export const ILO_ACCOUNT_SERVICE = 'ILO_ACCOUNT_SERVICE';
export const ILO_NETWORK = 'ILO_NETWORK';
export const ILO_ILO = 'ILO_ILO';
export const ILO_UPDATE_SERVICE = 'ILO_UPDATE_SERVICE';
export const ILO_SNMP_ALERTS = 'ILO_SNMP_ALERTS';
export const ILO_SNMP_SETTINGS = 'ILO_SNMP_SETTINGS';
export const ILO_SNMPV3_SETTINGS = 'ILO_SNMPV3_SETTINGS';
export const ILO_SNMPV3_USERS = 'ILO_SNMPV3_USERS';
export const ILO_SNMP_ALERT_DESTINATIONS = 'ILO_SNMP_ALERT_DESTINATIONS';
export const ILO_REVIEW = 'ILO_REVIEW';

// iLO settings configuration default form values
export const ILO_SETTINGS_CONFIGURATION_DEFAULTS = {
  iloSecurityConfigurationGroups: [],
  iloSnmpConfigurationGroups: [],
};
